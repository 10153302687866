/*global google*/
import { AutomatitCarousel } from './automatit_carousel.m.js';
import {getMapsHelper} from './util/mapsHelper.js';

const homeCaro = {
	caro: document.querySelector('#home_hero_caro'),
	init() {
		AutomatitCarousel({
			element: this.caro,
			images: [
				{src: "/dist/images/content/hero12.jpg", alt: ""},
				{src: "/dist/images/content/hero6.jpg", alt: ""},
				{src: "/dist/images/content/hero7.jpg", alt: ""},
				{src: "/dist/images/content/hero8.jpg", alt: ""},
				{src: "/dist/images/content/hero9.jpg", alt: ""},
				{src: "/dist/images/content/hero10.jpg", alt: ""},
				{src: "/dist/images/content/hero11.jpg", alt: ""},
			],
			imagesAsBackgrounds: true,
			intervalTiming: false,
			showDots: true,
			useChevrons: false,
		});
	}
};

const homeMap = {
	theMap: {
		ele: document.querySelector('#home_map'),
		instance: null
	},
	init: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		this.theMap.instance = mapsHelper.createMap({
			element: this.theMap.ele,
			locationElementSelector: '#home_map',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: 
						`<span class="map_pin">
							<img src="/dist/images/icons/ic_mappin_black.svg" alt="" />
						</span>`
				};
			}
		});

		google.maps.event.addListenerOnce(this.theMap.instance, 'idle', () => {
			this.theMap.instance.setZoom(14);
		});
	}
};

export const initHomeCaro = () => homeCaro.init();
export const initHomeMap = () => homeMap.init();