import { initRatesFilter } from './facility.js'; 
import { initContactMap, contactSubmit } from './contact.js';
import { initHomeCaro, initHomeMap } from './home.js';
import { initFaqs } from './faqs.js';
import { header } from './header.js';

if(document.querySelector('#home_hero_caro')) {
	initHomeCaro();
}

if(document.querySelector('#home_map')) {
	initHomeMap();
}

if(document.querySelector('#faqs_list')) {
	initFaqs();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'message']
	});
}

if (document.getElementById('rates_filter')) {
	initRatesFilter();
}

header.init();

if (document.getElementById('contact_map')) {
	initContactMap();
}