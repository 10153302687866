const faqs = {
	faqClick(e) {
		const clickedFaq = e.target.closest('h4');

		if(clickedFaq) {
			clickedFaq.closest('li').toggleAttribute('open');
		}
	},
	init() {
		document.querySelector('#faqs_list').addEventListener('click', e => this.faqClick(e));

		setTimeout(() => {
			document.querySelector('#faqs_list li h4').click();
		}, 500);
	}
};

export const initFaqs = () => faqs.init();
