const ratesFilter = {
	filterButtons: document.querySelectorAll('#rates_filter button'),
	ratesRows: document.querySelectorAll('.rates_row'),
	noUnits: document.querySelector('#no_units'),
	filterUnits(e) {
		this.noUnits.removeAttribute('hide');
		const clickedItem = e.target.closest('button');
		if (clickedItem !== null && clickedItem.hasAttribute('data-filter')) {
			let count = 0;
			let notSameFilter = true;
			this.filterButtons.forEach( (thisFilter) => {
				if (thisFilter.hasAttribute('active') && thisFilter === clickedItem) { 
					notSameFilter = false;
				}
				thisFilter.removeAttribute('active');
			});

			if (notSameFilter) {
				clickedItem.setAttribute('active','');
				this.ratesRows.forEach( (thisRow) => {
					if (thisRow.getAttribute('data-filter') === clickedItem.getAttribute('data-filter')) {
						count++;
						thisRow.removeAttribute('hide');
					} else {
						if (thisRow.hasAttribute('hide') === false) {
							thisRow.setAttribute('hide','');
						}
					}
				});

			} else {
				this.ratesRows.forEach( (thisRow) => {
					count++;
					thisRow.removeAttribute('hide');
				});
			}

			if (count > 0) {
				this.noUnits.setAttribute('hide','');
			}
            
		}
	},

	init() {
		if(document.getElementById('rates_filter_wrapper')){
			document.querySelector('#rates_filter ul').addEventListener('click', e => this.filterUnits(e));
		}
	}
};

export const initRatesFilter = () => ratesFilter.init();
