export const header = (function(){
	let my = {};

	function _toggleMenu(){
		document.getElementById('header_menu').classList.toggle('open');
		document.getElementById('header_nav').classList.toggle('open');
	}

	function _addListener(){
		document.getElementById('header_menu').addEventListener('click', _toggleMenu);
	}

	my.init = function(){
		_addListener();
	};

	return my;
})();